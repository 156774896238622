import { useEffect } from "react";
import { Link , useLocation, useParams} from "react-router-dom";
import { pageRoutes } from "../important/commanFunction";
import QrCodeSection from "./QrCodeSection";

const Footer = () => {
  const router =  useLocation()
  const { id } = useParams();
  useEffect(() => {
    // Function to set the title based on the current pathname
    const setTitle = (pathname) => {
      switch (pathname) {
        case pageRoutes.home:
          document.title = 'listy city';
          break;
        case pageRoutes.aboutPage:
          document.title = 'About Us';
          break;
        case pageRoutes.termsPage:
          document.title = 'Terms of Use';
          break;
        case pageRoutes.privacyPolicy:
          document.title = 'Privacy Policy';
          break;
        case pageRoutes.addEvent:
          document.title = 'Create an Event | Login';
          break;
        case pageRoutes.registerBusiness:
          document.title = 'listy city | List Your Business';
          break;
        case pageRoutes.enrollNowPage+'/'+id:
          document.title = 'listy city | Enroll Now';
          break;
        default:
          document.title = 'listy city';
          break;
      }
    };
    window.scrollTo({
      top: 0,
      behavior: "smooth", 
    });
    setTitle(router.pathname);
  }, [router.pathname]); 

  return (
    <>
    <QrCodeSection />
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex flex-column flex-md-row">
              <div className="footer-nav">
                <ul>
                  <li>
                  <Link to={pageRoutes.aboutPage}>About Us</Link>
                  </li>
                  <li>
                  <Link to={pageRoutes.privacyPolicy}>Privacy Policy</Link>
                  </li>
                  <li>
                  <Link to={pageRoutes.termsPage}>Terms of Use</Link>
                  </li>
                  <li>
                    <span>All Rights Reserved</span>
                  </li>
                </ul>
              </div>
              <div className="footer-social ms-md-auto">
                <ul>
                  <li>
                    <a href="https://www.instagram.com/listy.city" target={"_blank"}><img
                      src="/images/images/instagram.png" alt="Instagram Icon" /></a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/listy.city" target="_blank"><img
                      src="/images/images/facebook.png" alt="Facebook Icon" /></a>
                  </li>
                  {/* <li>
                    <a href="https://www.twitter.com/"><img src="/images/images/x-icon.png" alt="X Icon" /></a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;

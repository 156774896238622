import { Link } from "react-router-dom";
import { appDownloadUrl, pageRoutes} from "../important/commanFunction";

const HomePage = () => {
  return (
    <>
      <div id="heroCarousel" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-indicators">
          <button type="button" data-bs-target="#heroCarousel" data-bs-slide-to="0" className="active" aria-current="true"
            aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#heroCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#heroCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
        </div>
        <div className="carousel-inner">
          <div className="carousel-item active" data-bs-interval="10000">
            <div className="content p-2  px-md-4">
              <div className="content-text">
                <h2 className="text-center">Best from <span>your <br />city</span> and around!</h2>
                <p className="text-center w-100">Explore coolest events and<br /> services, or list your own!</p>
                <p className="mobile-border text-center w-100">Download to get exploring</p>
                <a href={appDownloadUrl} target="_blank" className="btn btn-white mx-auto mt-3">Get The App</a>
              </div>
              <div className="content-image d-none d-lg-block">
                <img src="/images/images/mobile-app-screenshot.png" alt="Mobile app screenshot" className="w-100" />
              </div>
            </div>
          </div>
          <div className="carousel-item" data-bs-interval="2000">
            <div className="content p-2  px-md-4">
              <div className="content-text">
                <h2 className="text-center">Discover <span>online, <br />offline</span> events!</h2>
                <p className="text-center w-100">Discover your next online and <br />offline event, or launch your <br />own!</p>
                <p className="mobile-border text-center w-100">Download to get exploring</p>
                <a href={appDownloadUrl} target="_blank" className="btn btn-white mx-auto mt-3">Get The App</a>
              </div>
              <div className="content-image d-none d-lg-block">
                <img src="/images/images/online-events.png" alt="Online Offline Events" className="w-100" />
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="content p-2  px-md-4">
              <div className="content-text">
                <h2 className="text-center">Reach the <span>right <br />customers</span></h2>
                <p className="text-center w-100">Showcase your products and <br />services to a larger audience</p>
                <p className="mobile-border text-center w-100">Download to get exploring</p>
                <a href={appDownloadUrl} target="_blank" className="btn btn-white mx-auto mt-3" >Get The App</a>
              </div>
              <div className="content-image d-none d-lg-block">
                <img src="/images/images/right-customers.png" alt="Find the Right Customers" className="w-100" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="unlock-city">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h2>Unlock the Best of <br />Your City</h2>
            </div>
          </div>
          <div className="row gap-3 gap-md-0">
            <div className="col-12 col-md-4 d-flex" >
              <div className="card align-items-start">
                <img src="/images/images/growth-icon.svg" alt="Growth Icon" />
                <div className="title-desc mt-4">
                  <h4 className="mb-4"  >Grow your business <br />with listy city</h4>
                  <p id="unlock_best">List your business with us and reach <br />people searching for services like yours!<br />Its quick, easy
                    and effective.</p>
                </div>
                <Link className="btn btn-purple text-white mt-auto" to={pageRoutes.registerBusiness}>
                  List Your Business</Link>
              </div>
            </div>
            <div className="col-12 col-md-4 d-flex">
              <div className="card align-items-start">
                <img src="/images/images/calendar-icon.svg" alt="Calendar Icon" />
                <div className="title-desc mt-4">
                  <h4 className="mb-4">Never miss a <br />cool event in your city</h4>
                  <p>Launch your next online or offline <br />event with listy and reach the <br />right audience.</p>
                </div>
                <Link className="btn btn-purple text-white mt-auto" to={pageRoutes.addEvent}>
                  Launch Your Event</Link>
              </div>
            </div>
            <div className="col-12 col-md-4 d-flex">
              <div className="card align-items-start">
                <img src="/images/images/vendor-icon.svg" alt="Vendor Icon" />
                <div className="title-desc mt-4">
                  <h4 className="mb-4">Find local services and events</h4>
                  <p>Whether you’re searching for a <br />fitness trainer, a trusted tutor, or such  <br />local services and events,
                    listy city has you covered.</p>
                </div>
                <a href={appDownloadUrl} className="btn btn-purple text-white mt-auto" target="_blank">Download App</a>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
};

export default HomePage;
